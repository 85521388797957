#addressSearchBar {
  position: relative;
}

#addressSearchBar label {
  color: red;
  font-size: 13px;
  font-weight: bold;
}
#addressSearchBar .mainBox {
  /* designs = 46px, other inputs on the page = 40px */
  /* height: 40px;  */
  display: flex;
  flex-direction: row;
  align-items: center;
  border: 1px solid #757575;
  border-radius: 4px;
  padding-left: 16px;
  padding-right: 16px;
  box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.14),
    0px 2px 2px 0px rgba(0, 0, 0, 0.12), 0px 1px 3px 0px rgba(0, 0, 0, 0.2);
}
#addressSearchBar .mainBox * {
  background-color: transparent;
  padding: 0px;
}

#addressSearchBar .MuiAutocomplete-popperDisablePortal {
  background-color: white;
  z-index: 101 !important;
}

#addressSearchBar .MuiAutocomplete-popperDisablePortal li {
  padding: 5px;
  border-bottom: 1px solid lightgray;
}

#addressSearchBar .MuiAutocomplete-popperDisablePortal li:hover {
  /* background-color: rgba(238, 238, 238); */
  background-color: #add8e670;
}

#addressSearchBar .MuiAutocomplete-popperDisablePortal li:active {
  background-color: lightblue;
}

#addressSearchBar .MuiInputBase-root {
  border: none !important;
  padding-top: 0px !important;
}

#addressSearchBar #innerInput {
  padding: 0px;
  border: none;
}

/* the "clear" text icon "x" */
#addressSearchBar .css-1q60rmi-MuiAutocomplete-endAdornment {
  position: relative;
}
#addressSearchBar
  .css-19im320-MuiAutocomplete-root
  .MuiFilledInput-root
  .MuiAutocomplete-endAdornment {
  right: 0px;
}

#addressSearchBar #addressLoading {
  position: absolute;
  /* right: 5px; */
  right: 22px;
  background-color: #fff;
}

.MuiFilledInput-underline:before,
.MuiFilledInput-underline:after {
  border: none !important;
}

#addressSearchBar .selectedAddressWrap {
  /* flex-grow: 1; */
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  /* justify-content: space-between; */
  margin: 10px 0px 5px;
  padding-right: 18px;
}

#addressSearchBar .CheckBoxIcon,
#addressSearchBar .CheckBoxOutlineIcon {
  /* align-self: center; */
  color: #a10070;
}
#addressSearchBar .CheckBoxIcon:hover,
#addressSearchBar .CheckBoxOutlineIcon:hover {
  cursor: pointer;
  opacity: 40%;
}

#addressSearchBar .checkBoxMapIcon {
  justify-self: flex-end;
  color: #8f98a3;
}

#addressSearchBar #error {
  display: none;
}

/* .borderRightNone input {
  border-right: none !important;
}

.borderLeftNone input {
  border-left: none !important;
} */



h1 {
    font-size: 36px;
    line-height: 40px;
    font-weight: 700;
}



.colorMagenta {
    color: '#A10070',
}
.colorBlue {
    color: '#007DB3';
}



.backgroundMagenta {
    background-color: '#A10070';
}
.backgroundBlue {
    background-color: '#007DB3';
}

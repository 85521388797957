.our-profile h1 {
  text-align: left;
}

.our-profile label {
  font-weight: bold;
}

/* checkbox label width */
.basicsServices .MuiTypography-body1 {
  width: 250px !important;
}
/************* OUR PROFILE NAVBAR ************/

.our-profile p {
  margin-top: 0px;
}

.profilenavbar {
  color: white;
  margin-bottom: 20px;
  position: relative;
}
.profilenavbar span {
  color: black;
  margin: 50px;
}

.ourprofile-navlink {
  text-decoration: none !important;
  text-align: center;
  color: black;
}

.ourprofile-navlink.current {
  border-bottom: 3px solid #007db3;
  width: 10%;
}

.edit-button {
  /* position: absolute;
  right: 10%;
  top: 18%; */
  margin-top: -10px;
  /* margin-left: 40px; */
}

.edit-button-mobile {
  margin-right: 15px;
}

.edit-button-edit {
  position: absolute;
  right: 10%;
  top: 18%;
  color: lightgrey;
}

.edit-button-edit figcaption {
  color: #8f98a3;
}

.previewbutton {
  margin-top: 2.5%;
  position: absolute;
  right: 30%;
}

.cancelbutton {
  margin-top: 55%;
}
/************* OUR PROFILE/BASICS ************/
.mobile-avatar-orgname {
  display: flex;
}

.mobile-avatar-orgname .triangle-topleft {
  border-top-left-radius: 0;
  border-top-right-radius: 5px;
  border-bottom-left-radius: 5px;
}

.mobile-avatar-orgname .triangle-bottomright {
  border-top-left-radius: 0;
  border-bottom-right-radius: 0;
  border-top-right-radius: 5px;
  border-bottom-left-radius: 5px;
  position: unset;
}

.basics-mobile-avatar {
  height: max-content;
  position: relative;
  transform: scaleX(-1);
}

.basics-mobile-avatar div {
  bottom: -1%;
}

.basicsform,
.basic-edit-form {
  width: 55vw;
  display: flex;
  /* //makes a new column starts with ages served */
  justify-content: space-between;
}
.basicsform-non-edit,
.basic-non-edit-form {
  width: 55vw;
  display: flex;
  /* //makes a new column starts with ages served */
  justify-content: space-between;
}

/* .basicsform input {
  width: 100%;
} */

.inputfield {
  width: 10.42vw;
}

.errorp {
  color: #a10070;
}

.errorp::before {
  display: inline;
  content: "⚠ ";
}

.our-profile .basic-edit-form textarea,
.our-profile .basic-edit-form input {
  display: block;
  box-sizing: border-box;
  border-radius: 4px;
  border: 1px solid black;
  padding: 8px 15px;
  font-size: 14px;
  height: 3em;
}
/* 
@media screen and (min-width: 768px) {
  .basicsform input { */
/* input {
  width: 255px;
} */
/* }
} */

.our-profile input.hours {
  width: initial;
}

.hours-span {
  margin: 0px 5px;
}

.phonebasics {
  border-color: black;
}

.disablecheck {
  color: black;
  font-size: 1em;
}

/* Details form */
.detailsform {
  width: 60vw;
  display: flex;
  justify-content: space-between;
}

.detailseditform {
  width: 60vw;
  margin-bottom: 50px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

/* Nonpublic form */
.nonpublicform {
  width: 60vw;
  display: flex;
  justify-content: space-between;
}

.nonpubliceditform {
  width: 50vw;
  margin-bottom: 50px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

/************* OUR PROFILE/BASICS ************/
.pdetails {
  width: 800px;
}

@media screen and (max-width: 767px) {
  .detailsform {
    width: 100%;
  }
  .pdetails {
    width: 120%;
  }
  .PnonpublicDisplay {
    width: 90vw;
  }
  .detailseditform > div,
  .nonpubliceditform,
  .nonpubliceditform textarea {
    width: 90vw;
  }
  .detailseditform form {
    width: 100vw;
  }
  .detailseditform textarea {
    width: 90%;
  }
  .nonpublicform {
    width: 100%;
  }
  /* .basic-edit-form .label-basics input[name^="provider_address"], */
  .our-profile-edit-address,
  .our-profile-edit-social {
    margin-right: 0;
    margin-bottom: 5px;
    width: 90vw !important;
  }
}

.url-css {
  word-wrap: break-word;
  width: 400px;
}

.MuiCheckbox-colorPrimary.Mui-disabled.Mui-checked {
  color: #a10070 !important;
}

.MuiFormControlLabel-root {
  height: 30px;
}

.time-field-div {
  display: flex;
  margin-right: 3em;
}

.hours .MuiInputBase-root {
  min-width: 255px !important;
  border: black !important;
  min-width: unset !important;
}

.labelbold span.MuiTypography-body1 {
  color: black !important;
}

.label-basics {
  margin: 0 0 20px;
}

.blog-left {
  cursor: pointer;
  width: 35px;
  height: 20px;
  border-radius: 2px;
  border: 3px solid black;
  background-color: lightgrey;
}

.blog-right {
  cursor: pointer;
  width: 35px;
  height: 20px;
  border-radius: 2px;
  border: 3px solid black;
  background-color: gray;
}
.paddingTop25 {
  padding-top: 25px;
  padding-left: 0.7em;
}
@media screen and (min-width: 768px) {
  .save-button {
    position: absolute;
    left: 18em;
  }
}

/************* OUR PROFILE/NONPUBLIC ************/
/* .Pnonpublic {
  width: 800px;
} */

/* FONTSTYLES */
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400&display=swap");

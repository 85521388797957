.App {
  /* margin-left: 280px; */
  font-size: 16px;
  /*   text-align: center; */
}

iframe {
  pointer-events: none;
}

span,
div {
  font-family: "Open Sans", sans-serif;
}

h1 {
  margin-top: 40px;
  color: #007db3;
  font-family: "Montserrat", sans-serif;
}

.MuiTypography-body1 {
  /* min-width: fit-content !important; */
  width: 220px !important;
  font-size: 16px;
  font-family: "Open Sans", sans-serif;
}

.addProviderForm .MuiTypography-body1 {
  width: 500px !important;
}

body {
  font-family: "Open Sans", sans-serif;
  background-color: white !important;
}

strong {
  min-width: fit-content;
}
/*****************************LOGIN*********************/
.login-page {
  margin: auto;
  margin-top: 20vh;
  width: 350px; /* has media query */
  font-style: Helvetica;
}

.login-page input {
  box-sizing: border-box;
  padding: 8px;
  font-size: 14px;
  height: 3em;
}

main {
  margin-left: 288px;
  margin-top: 28px;
}

.button-forgot {
  width: 78px;
  height: 40px;
  margin-top: 10px;
  background-color: black;
  color: white;
  font-weight: 600;
  border-radius: 5px;
}
.makeStyles-primaryButton-5 {
  margin-left: 0% !important;
}
.button-signin {
  width: 78px;
  height: 40px;
  /* margin-left: 88px; */
  margin-bottom: 100px;
  background-color: black;
  color: white;
  font-weight: 600;
  border-radius: 5px;
}

.fontHelvetica {
  font-style: Helvetica;
}

.icon-eye-login svg
/* .search-icon svg  */ {
  color: #007db3;
}
/*****************************DRAWERS*********************/

.orgname {
  height: 30%;
}
.orgname h2 {
  width: 65%;
  font-weight: normal;
  padding-left: 0px;
  position: absolute;
  bottom: 88%;
  left: 28%;
  word-break: break-all;
  word-wrap: break-word;
}

.MuiList-padding {
  padding: 0px !important;
}

.MuiButtonBase-root.Mui-selected:focus {
  background-color: #e5f2f7 !important;
}

.MuiButtonBase-root.Mui-selected:hover {
  background-color: #e5f2f7 !important;
}

.MuiButtonBase-root.Mui-selected {
  background-color: #ffff !important;
}

@media screen and (min-width: 768px) {
  .drawer-desktop {
    height: 90vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .drawer-desktop-auth {
    height: 90vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }
  .drawer3 {
    margin-bottom: 3em;
  }
}

.drawer2 {
  /* width: 100%; */
  position: absolute;
  top: 15%;
}

@media screen and (max-height: 600px) {
  .mobile-divider-rule-drawer3-toggle {
    background: none !important;
  }
  .drawer3 {
    margin-bottom: 3em;
  }
  .orgname-grand-wrapper-mobile {
    overflow-y: unset !important;
  }
}

/* .drawer3 {
  margin-top: 40vh;
  width: 100%;
} */

.provider-name-drawer {
  position: absolute;
  bottom: 87.5%;
  left: 30%;
  font-family: Helvetica;
  font-size: 20px;
  margin-bottom: 0px;
}

.triangle-topleft {
  position: absolute;
  bottom: 88.5%;
  left: 2%;
  width: 30px;
  height: 30px;
  border-top: solid 25px gray;
  border-left: solid 25px gray;
  border-top-left-radius: 5px;
  border-right: solid 25px transparent;
  border-bottom: solid 25px transparent;
}

.triangle-bottomright {
  width: 30px;
  height: 30px;
  position: absolute;
  bottom: 88.5%;
  left: 2%;
  border-bottom-right-radius: 5px;
  border-top: solid 25px transparent;
  border-left: solid 25px transparent;
  border-right: solid 25px lightgray;
  border-bottom: solid 25px lightgray;
}

.icondrawer {
  margin-right: 30px;
  height: 24px !important;
  width: 24px !important;
  color: #007db3;
}

.active span {
  font-weight: bold;
}

/* LeftDrawer.js media query for main and left drawer overall view */
@media screen and (max-width: 767px) {
  main {
    margin-left: 10px;
    /* overscroll-behavior: none; */
    /* overflow-y: hidden; */
  }

  /* LeftDrawer.js and Settings.js mobile optimization styling */
  .settings-wrapper {
    width: 95vw;
  }
  /* .password-field-mobile {
    padding-right: 57.5% !important;
  } */
}
@media screen and (max-height: 767px) and (orientation: landscape) {
  /* .password-field-mobile {
    padding-right: 49% !important;
  } */
  /* .settings-style-email {
    margin-right: none;
  } */
}

/* LeftDrawer.js media query for portrait */
@media screen and (orientation: portrait) and (max-width: 767px) {
  .MuiPaper-root.MuiDrawer-paper.MuiDrawer-paperAnchorLeft.MuiDrawer-paperAnchorDockedLeft.MuiPaper-elevation0 {
    height: 110vh;
    width: 100vw;
    /* overscroll-behavior: none;
    overflow-y: hidden; */
    margin-top: 1em;
  }
  .mobile-divider-rule {
    width: 100vw;
  }
  .drawer3-wrapper-mobile {
    padding-bottom: 100px;
  }
  /* .settings-style-email {
    margin-right: none;
  } */
}
/* LeftDrawer.js media query for landscape */
@media screen and (max-height: 767px) and (max-width: 767px) and (orientation: landscape) {
  main {
    margin-left: 10px;
    /* overscroll-behavior: none; */
    /* overflow-y: hidden; */
  }
  .MuiPaper-root.MuiDrawer-paper.MuiDrawer-paperAnchorLeft.MuiDrawer-paperAnchorDockedLeft.MuiPaper-elevation0 {
    width: 100vw;
    height: 100vh;
  }
  .orgname-grand-wrapper-mobile {
    position: relative;
    width: 100%;
    display: flex;
  }
  .mobile-divider-rule {
    width: 100vw;
  }
  .provider-name-drawer {
    margin-left: -18%;
  }
  .drawer2-wrapper-mobile {
    width: 40%;
  }
  .drawer3-wrapper-mobile {
    width: 60%;
    /* margin-top: -13.5%;
    margin-left: -10%; */
  }
  .drawer3-wrapper-mobile-auth {
    width: 60%;
    margin-top: -1em;
  }
  /* .settings-style-email {
    margin-right: none;
  } */
}

/* FONTSTYLES */
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@700;900&display=swap");

:focus {
  outline: none;
}

.button,
button {
  cursor: pointer;
}

.overflowScroll {
  overflow: scroll;
}

/* Font sizes */
.fontSize12 {
  font-size: 12px;
}

.fontSize14 {
  font-size: 14px;
}

.fontSize15 {
  font-size: 15px;
}

.fontSize16 {
  font-size: 16px;
}

.fontSize18 {
  font-size: 18px;
}

.fontSize20 {
  font-size: 20px;
}

.fontSizeNormal {
  font-size: normal;
}
/* Font weight */
.bold {
  font-weight: bold !important;
}

/* Line heights */
.lineHeight5 {
  line-height: 5px;
}

.lineHeight16 {
  line-height: 16px;
}

.lineHeight17 {
  line-height: 17px;
}

.lineHeight18 {
  line-height: 18px;
}

.lineHeight20 {
  line-height: 20px;
}

.lineHeight21 {
  line-height: 21px;
}

.lineHeight22 {
  line-height: 22px;
}

/* Text alignment */
.textAlignLeft {
  text-align: left;
}

.textAlignCenter {
  text-align: center;
}

/* Text decoration */
.textDecorationUnderline {
  text-decoration: underline;
}

/* Width */
.width20 {
  width: 20px;
}

.width44 {
  width: 44px;
}

.width200 {
  width: 200px;
}

.width300 {
  width: 300px;
}

.width400 {
  width: 440px;
}

.width500 {
  width: 500px;
}

.width42vw {
  width: 42vw;
}

.width40Percent {
  width: 40%;
}

.width45Percent {
  width: 45%;
}
.width48Percent {
  width: 48%;
}

.width50Percent {
  width: 50%;
}

.width52Percent {
  width: 52%;
}

.width75Percent {
  width: 75%;
}

.width75vw {
  width: 75vw;
}

.width80Percent {
  width: 80%;
}

.width90vw {
  width: 90vw;
}

.width95Percent {
  width: 95%;
}

.width95vw {
  width: 95vw;
}

.width98Percent {
  width: 98%;
}

.width14Height14 {
  width: 14px;
  height: 14px;
}

.width20Height20 {
  width: 20px;
  height: 20px;
}

.width28Height28 {
  width: 28px;
  height: 28px;
}

.width60 {
  width: 60px;
}

.width100 {
  width: 100px;
}

.width100Percent {
  width: 100%;
}

.width100vw {
  width: 100vw;
}

.widthAuto {
  width: auto;
}

.minWidth320 {
  min-width: 320px !important;
}

.maxWidth95Percent {
  max-width: 95%;
}

.addresswarning {
  font: "Open Sans";
  font-weight: 500;
  font-size: 11px;
  line-height: 15px;
  color: #cd0029;
}

.iconSize15 {
  /* svg is 20px wh so path can be 14.58px */
  width: 20px !important;
  height: 20px !important;
}

/* Height */
.height100Percent {
  height: 100%;
}

.height17 {
  height: 17px;
}

.height20 {
  height: 20px;
}

.height35 {
  height: 35px;
}

.maxHeight55vh {
  max-height: 55vh !important;
}

.maxHeight85vh {
  max-height: 85vh !important;
}

/* position */
.positionRelative {
  position: relative;
}

.positionAbsolute {
  position: absolute;
}

.positionRight0 {
  right: 0px;
}

.positionTopBottom0 {
  top: 0;
  bottom: 0;
  margin: auto;
}

.displayBlock {
  display: block;
}

.displayNone {
  display: none;
}

/* Flex props */
.flexRow {
  display: flex;
  flex-direction: row;
}

.flexRowReverse {
  display: flex;
  flex-direction: row-reverse;
}

.flexColumn {
  display: flex;
  flex-direction: column;
}

.flexColumnReverse {
  display: flex;
  flex-direction: column-reverse;
}

.flex1 {
  flex: 1;
}

.justifyCenter {
  justify-content: center;
}

.alignFlexStart {
  align-items: flex-start;
}

.alignCenter {
  align-items: center;
}

.alignSelfCenter {
  align-self: center;
}

.alignFlexEnd {
  align-items: flex-end;
}

.justifySpaceBetween {
  justify-content: space-between;
}

.flexWrap {
  flex-wrap: wrap;
}

.flexGrow2 {
  flex-grow: 2;
}

.order2 {
  order: 2;
}

/* Padding */
.padding11-5-0 {
  padding: 11.5px 0px;
}

.padding12-16 {
  padding: 12px 16px;
}

.padding15 {
  padding: 15;
}

.padding20-0 {
  padding: 20px 0px;
}

.padding30-40-20-30 {
  padding: 50px 40px 20px 30px;
}

.paddingLeft8 {
  padding-left: 8px;
}

.paddingRight0 {
  padding-right: 0 !important;
}

.paddingRight5 {
  padding-right: 5px;
}

.paddingRight8 {
  padding-right: 8px;
}

.paddingTop10 {
  padding-top: 10px;
}

.paddingTop27 {
  padding-top: 27px;
}

.paddingTop25 {
  padding-top: 25px;
}

.paddingBottom20 {
  padding-bottom: 20px;
}

.paddingBottom60 {
  padding-bottom: 60px;
}

/* this is padding for media queries */
/* .paddingBottom60 {
  
  padding-left: 10px;
  padding-right: 10px;
} */

/* Margin */
.margin0 {
  margin: 0px;
}

.margin2-10-20 {
  margin: 2px 10px 20px;
}

.marginTopRevert {
  margin-top: revert;
}

.marginTop0 {
  margin-top: 0px !important;
}

.marginTop5 {
  margin-top: 5px !important;
}

.marginTop25 {
  margin-top: 25px;
}

.marginTop15 {
  margin-top: 15px;
}

.marginTop60Percent {
  margin-top: 65vh;
}

.marginRight4 {
  margin-right: 4px;
}

.marginRight11 {
  margin-right: 11px;
}

.marginRight10 {
  margin-right: 10px;
}

.marginRight20 {
  margin-right: 20px;
}

.marginLeftAuto {
  margin-left: auto;
}

.marginLeft5 {
  margin-left: 5px;
}

.marginLeft15 {
  margin-left: 15px;
}

.marginLeftMinus5 {
  margin-left: -5px;
}

.marginLeftMinus45 {
  margin-left: -45px;
}

.marginLR15 {
  margin-left: 15px;
  margin-right: 15px;
}

.marginBottom0 {
  margin-bottom: 0px;
}

.marginBottom5 {
  margin-bottom: 5px;
}

.marginBottom10 {
  margin-bottom: 10px !important;
}

.marginBottom15 {
  margin-bottom: 15px;
}

.marginBottom16 {
  margin-bottom: 16px;
}

.marginBottom20 {
  margin-bottom: 20px;
}

.marginBottom24 {
  margin-bottom: 24px;
}

.marginBottom26 {
  margin-bottom: 26px;
}

.marginBottom30 {
  margin-bottom: 30px;
}

.marginBottom36 {
  margin-bottom: 36px;
}

.marginBottom40 {
  margin-bottom: 40px;
}

.marginBottom45 {
  margin-bottom: 45px;
}

.marginBottom60 {
  margin-bottom: 60px;
}

.marginBottom20Percent {
  margin-bottom: 20vh;
}

.marginBottom30Percent {
  margin-bottom: 37vh;
}

/* Colors */
.colorGrey2 {
  color: #8f98a3;
}
.colorGrey3 {
  color: #515963;
}

.colorBlack1 {
  color: #9393aa;
}

.colorBlack2 {
  color: #4b4b4c;
}

.colorBlack3 {
  color: #121232;
}

.colorRed {
  color: #cd0029;
}

.colorBlue0 {
  color: #0645ad;
}

.colorBlue1 {
  color: #0024b3;
}

.colorWhite {
  color: white;
}

/* background colors */
.backgroundColorWhite {
  background-color: #ffffff;
}

.backgroundColorBlack {
  background-color: #000000;
}

.backgroundColorBlue0 {
  background-color: #0645ad;
}

/* opacity */
.opacity3 {
  opacity: 0.3;
}

/* Border Radius */
.borderRadius4 {
  border-radius: 4px;
}

.borderRadius5 {
  border-radius: 5px;
}

.borderRadius8 {
  border-radius: 8px;
}

.borderRadiusRight5 {
  border-radius: 0 5px 5px 0;
}

/* Borders */
.borderNone {
  border: none;
}

.border1Grey0 {
  border: 1px solid #d5d8dd; /* #D5D8DD with 0.5 opacity */
}

.borderBottom1Grey0 {
  border-width: 0px;
  border-bottom-width: 1px;
  border-color: #d5d8dd;
  border-style: solid;
}
/* this has to do with basicsMobile1 underline border for the edit mode navbar for the non public information  */
.borderBottom1Grey0-mobile {
  width: 140%;
}

.borderleft1Grey0 {
  border-width: 0px;
  border-left-width: 1px;
  border-color: #d5d8dd;
  border-style: solid;
}

.border1Grey1 {
  border: 1px solid #d5d8dd;
}

.border1ColorGrey3 {
  border: 1px solid #969696;
}

.border1ColorGrey4 {
  border: 1px solid #757575;
}

.border1colorBlack1 {
  border: 1px solid #121232;
}

.border2ColorRed {
  border: 2px solid #cd0029;
}

.underline {
  text-decoration: underline;
}

/* Button style classes */
.btnOffWhite {
  background-color: #fafafc;
}

.btnGrey0 {
  background-color: #d5d8dd;
}

.btnMagenta0 {
  background-color: #a10070;
}

.btnWithStyle {
  color: #ffffff;
  padding: 6px 16px;
  border: none;
}

.btnNoStyle {
  background-color: transparent;
  border: none;
  padding: 0px;
}

.btnNoFocus:focus {
  outline: none;
}

.cursorDefault {
  cursor: default;
}

.cursorPointer {
  cursor: pointer;
}
/* this is for card for provider list in mobile view */

/* .cardOrgWrapper {
	  display: flex;
  flex-direction: row;
  border: 1px solid lightgrey;
 	}
.serviceTitleRow {
	  display: flex;
	  flex-direction: row;
  justify-content: space-between;
 	}
.geoSquareCol{
	  width: 20%;
	}
.serviceOrgCol{
  width: 80%;
 	}
.geoSquare{
 margin-left: 8px;
 margin-top: 2px;
padding: 15px;
	}
.titleOrg{
   width: 80%;
    }
.pointRight {
margin-top: 15px;
 padding: 5px;
 opacity: 0.5;
	} */

/**** All Tables (providerList & peopleList) ****/

.hideTable * {
  height: 0px;
  opacity: 0;
  z-index: -1;
}

.peopleList .tables .MuiTable-root {
  width: 100% !important;
}
/* Entire table container */
.tables .MuiPaper-elevation2 {
  max-width: 1300px;
  box-shadow: none !important;
}
/* Search bar row container and footer container! */
.tables .MuiToolbar-gutters {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.peopleList .MuiToolbar-gutters {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.peopleList .MTableToolbar-spacer-44 {
  display: none;
}

/* Row with search bar */
.tables .MTableToolbar-root-11 {
  min-height: 10px !important;
}

/* Search bar */
.tables .MuiFormControl-root.MuiTextField-root {
  width: 100%;
  flex-direction: row !important;
  align-items: center;
  padding: 0px !important;
}

.tables .MuiFormControl-root.MuiTextField-root .MuiInputBase-root {
  min-width: 1px !important;
  width: 231px;
  height: 30px;
  flex-direction: row-reverse;
  margin-right: 13px !important;
  border-radius: 5px;
  border: 1px solid #757575;
  padding: 0px !important;
}

.peopleList .MuiFormControl-root.MuiTextField-root {
  width: auto;
}

.providerList .MuiToolbar-gutters .MuiFormControl-root.MuiTextField-root:after {
  content: "Search for a specific organization or use the filters below." !important;
}

/* exact age input for age filter in ServiceProviders.js */
.providerList .ageTextField {
  display: block;
}
.providerList .ageTextField input {
  height: 10%;
  /* border: 1px solid #d5d8dd; */
  border-radius: 5px;
  padding: 6px;
}

.ageTextField .MuiOutlinedInput-root.MuiInputBase-root {
  width: 100% !important;
  height: 2em !important;
  /* border: 1px solid #d5d8dd !important; */
}

.ageTextField p {
  width: 150%;
  margin-left: 0;
}

.ageRangeWrapper > p {
  padding: 5px;
}

.ageWrapper .MuiFormControl-root.MuiTextField-root .MuiInputBase-root {
  border: none;
}

/* Chip or Tippy's z-index adjustment so Location filter autocomplete dropdown will show */
/* .locationFilterContainer div:last-child div {
  z-index: 100 !important;
} */

/* EditSavedSearchTitleModal */
#edit-saved-search-title-input {
  padding: 10px;
}

/* https://annie-cannons.atlassian.net/browse/REF-258 */
.peopleList .MuiFormControl-root.MuiTextField-root:before {
  /* content: "Search" !important; */
  font-size: 16px;
  line-height: 21px;
  margin-right: 15px;
}

.peopleList .MuiFormControl-root.MuiTextField-root .MuiInputBase-root {
  width: 205px;
  border: 1px solid #d5d8dd !important;
  margin-right: 0px !important;
}

.tables .MuiFormControl-root.MuiTextField-root .MuiInputBase-input {
  height: auto;
}
.tables
  .MuiFormControl-root.MuiTextField-root
  .MuiInputBase-input::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  opacity: 0;
}
.tables
  .MuiFormControl-root.MuiTextField-root
  .MuiInputBase-input::-moz-placeholder {
  /* Firefox 19+ */
  opacity: 0;
}
.tables
  .MuiFormControl-root.MuiTextField-root
  .MuiInputBase-input:-ms-input-placeholder {
  /* IE 10+ */
  opacity: 0;
}
.tables
  .MuiFormControl-root.MuiTextField-root
  .MuiInputBase-input:-moz-placeholder {
  /* Firefox 18- */
  opacity: 0;
}

.tables .MuiInput-underline:before {
  display: none;
}

.tables .MuiFormControl-root.MuiTextField-root button {
  display: none;
}
/* Search icon container */
.tables
  .MuiFormControl-root.MuiTextField-root
  .MuiInputAdornment-positionStart {
  /* svg.MuiSvgIcon-root */
  width: 46px;
  height: 100%;
  flex-direction: row;
  justify-content: center;
  background: #0024b3;
  margin: 0px !important;
}

.peopleList
  .MuiFormControl-root.MuiTextField-root
  .MuiInputAdornment-positionStart {
  background: #0024b3;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.peopleList .MuiInputAdornment-root.MuiInputAdornment-positionStart {
  border-left: 1px solid #d5d8dd;
}

.peopleList .MuiInputAdornment-root.MuiInputAdornment-positionStart svg {
  display: none;
}

.peopleList .MuiInputAdornment-root.MuiInputAdornment-positionStart::after {
  content: "";
  width: 45px;
  height: 30px;
  display: block;
  background-image: url("./Assets/personSearchIcon.png");
  background-size: 20px auto;
  background-position: center;
  background-repeat: no-repeat;
}

.tables
  .MuiFormControl-root.MuiTextField-root
  .MuiInputAdornment-positionStart
  svg {
  margin: 0px;
  color: #ffffff;
}
/* */
/* added media queries */
/* @media only screen and (max-width: 768px) {
  .providerList .filterBtns {
    padding: 10px;
  }
    .Component-horizontalScrollContainer-50{
      padding-left: 10px;
    padding-right: 10px;
    }

  
} */

/* Filter buttons */
.providerList .filterBtns {
  font-size: 15px !important;
  line-height: 17px !important;
  color: #4b4b4c !important;
  background-color: #ffffff !important;
  box-shadow: 0px 0px 0px 1px #969696 !important;
  margin: 8.5px 8px 8.5px 1.5px !important;
  border-radius: 8px !important;
}

.providerList .filterBtns:hover,
.providerList .filterBtns.open,
.providerList .filterBtns.active {
  box-shadow: 0px 0px 0px 2px #000000 !important;
}

.providerList .filterBtns.active {
  background-color: rgba(196, 196, 196, 0.2) !important;
}

.providerList .filterBtns .MuiChip-label {
  padding: 10px 25px;
}

/* Filter popovers */
.providerList .filterContainer.age > div:first-child + div {
  /* transform: translate3d(0px, 42px, 0px) !important; */
  margin-left: 98px !important;
  /* offset: 98px; */
}

.providerList .filterContainer .tippy-box {
  max-width: 100% !important;
}

.providerList .filterContainer.services div:nth-child(2) .tippy-box {
  position: relative;
  left: 150px;
}

.providerList .filterPopovers {
  width: 305px !important;
  padding: 37px 26px 23px 27px !important;
  background-color: #ffffff;
  border: 1px solid #969696;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
}

.providerList .filterPopovers.services {
  width: unset !important;
}

.providerList .filterPopovers.services div:last-child .MuiTypography-body1 {
  width: 250px !important;
}

.providerList .MuiIconButton-colorSecondary:hover {
  background-color: transparent !important;
}

.providerList .filterPopovers svg {
  margin: 0px;
}

.providerList .filterPopovers svg path {
  color: rgba(0, 0, 0, 0.25);
}

.providerList .filterPopovers .checkbox.checked svg path {
  color: #8f98a3;
}

.providerList .filterPopovers .MuiFormControlLabel-label {
  margin-left: 5px;
}

.modal-text {
  margin: 1em 0 1.5em !important;
}

.modal-button-first {
  margin: 1em 0 !important;
}
.modal-button-last {
  margin: 1em 0 -0.5em !important;
}

.modal-button-stacked {
  text-align: center;
}

/* Save a search modal */
/* Name Textfield input */
.saveSearchNameInput {
  width: 100%;
}

.saveSearchNameInput input {
  /* width: 100%; */
  font-size: 16px;
  line-height: 18px;
  border: 1px solid #d5d8dd;
  border-radius: 5px;
  padding-left: 15px;
  padding-right: 15px;
  margin-bottom: 0px;
}

.saveSearchNameInput .MuiInput-underline:before {
  border: 0px !important;
}

.saveSearchNameInput.error input {
  border: 2px solid #cd0029;
  margin-right: 11px;
}

/* person profile container/Textfield */
.saveASearchModal .participantProfileContainer .MuiInputBase-root {
  min-width: 1px !important;
  height: 100%;
  padding-left: 15px;
  padding-right: 44px;
  border: 0;
}

.saveASearchModal .participantProfileContainer .MuiInput-underline:before {
  border: 0px !important;
}

/* clear button/container that shows up */
.saveASearchModal .participantProfileContainer .MuiAutocomplete-endAdornment {
  width: 41px;
  height: 100%;
  top: 0;
  right: 1px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.saveASearchModal
  .participantProfileContainer
  .MuiAutocomplete-endAdornment
  button {
  width: 100%;
  height: 100%;
  background-color: #fff;
  color: #8f98a3;
  border-radius: 5px;
  z-index: 2;
}

/* autocomplete popper */
.saveASearchModal .participantProfileContainer .MuiAutocomplete-popper {
  width: calc(100% - 2px) !important;
  top: 27px !important;
  left: 0;
  right: 0;
  margin: 0% auto;
}

.saveASearchModal
  .participantProfileContainer
  .MuiAutocomplete-popper
  .MuiAutocomplete-paper {
  max-height: 200px;
  overflow-y: scroll;
  border: 0px;
  /* border: 1px solid #d5d8dd; */
  border-top: 0px;
  border-bottom: 0px;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  /* box-shadow: 0px 0px 0px 1px #D5D8DD; */
  box-shadow: 1px 0px #d5d8dd, -1px 0px 1px #d5d8dd;
}

.saveASearchModal .participantProfileContainer .MuiAutocomplete-popper ul {
  max-height: 200px;
}

.tables .Component-horizontalScrollContainer-11 {
  border: 1px solid #d5d8dd80;
}

.tables .MuiTableHead-root {
  border-top-width: 1px !important;
  border-top-style: solid !important;
  border-left-width: 1px !important;
  border-left-style: solid !important;
  border-right-width: 1px !important;
  border-right-style: solid !important;
  border-color: #d5d8dd;
}

.tables .MuiTableRow-head .MuiTableCell-root {
  /* Column title's row cells */
  padding-top: 12px !important;
  padding-bottom: 12px !important;
}
.tables th.MuiTableCell-head {
  font-size: 15px;
  font-family: "Open sans";
  line-height: 21px;
  font-weight: bold;
  color: #4b4b4c;
}

.tables th.MuiTableCell-head span div,
.tables th.MuiTableCell-head div {
  color: #007db3;
}

.tables .MuiTableRow-head svg {
  opacity: 1;
  color: #8f98a3 !important;
}

.providerList .orgAvatars {
  width: 40px;
  height: 40px;
  border-width: 20px;
  border-style: solid;
}

/* Each row in the table */
.tables .MuiTableBody-root tr:has(td) {
  border-left-width: 1px !important;
  /* border-top: 1px solid #d5d8dd !important; */
  border-left-style: solid !important;
  border-right-width: 1px !important;
  border-right-style: solid !important;
  border-color: #d5d8dd;
  cursor: pointer;
}

/* Table footers */
.tables .MuiTableCell-footer {
  border: none !important;
}

.tables .MuiTablePagination-toolbar * {
  color: #0024b3;
}

.tables .MuiSelect-select:focus {
  background-color: transparent !important;
}

.tables .MuiTablePagination-select div:before {
  content: "Show ";
}

.tables .MuiTablePagination-select div {
  margin-right: 40px;
}

.tables .MuiTableCell-footer .MuiIconButton-root svg,
.MuiTableCell-footer .MuiIconButton-root svg {
  color: #8f98a3;
  margin-right: 0px !important;
}

/* Modals */
.modalPlacement {
  display: flex;
  align-items: center;
  justify-content: center;
}

.providerList
  .saveSearchSuccessSnackbar
  .MuiPaper-root.MuiSnackbarContent-root {
  background-color: #000000;
}

.providerList .saveSearchSuccessSnackbar .MuiSnackbarContent-message {
  margin: auto;
}

.providerList .saveSearchSuccessSnackbar .MuiSnackbarContent-action {
  padding: 0px;
  margin: 0px;
}

.addProviderForm label {
  font-weight: bold;
}

.addProviderForm input {
  /* width: 100%; */
  display: block;
  box-sizing: border-box;
  border-radius: 4px;
  border: 1px solid black;
  padding: 8px 15px;
  font-size: 14px;
  height: 3em;
}
.addProviderForm input.hours {
  width: 150px !important;
}
/* Add a Provider Page Age Input */
.addProviderForm .ageRangeWrapper > div {
  width: 100px !important;
}

/* MEDIA QUERY */

/* Mobile */

@media screen and (max-width: 767px) {
  .tables.providerList {
    /* overscroll-behavior: none;
    overscroll-behavior-y: contain; */
    overscroll-behavior: auto;
    overflow-y: hidden;
  }
  /* .tables.providerList * {
    overscroll-behavior: none !important;
  } */
  .providerList .filterContainer.age > div:first-child + div {
    margin-left: 0 !important;
  }
  .providerList
    .MuiToolbar-gutters
    .MuiFormControl-root.MuiTextField-root:after {
    /* content: "Search for a specific organization or use the filters above." !important; */
    display: none;
  }
  .providerList.tables .MuiToolbar-gutters {
    order: 2;
  }

  .toolbarMobile {
    display: flex;
    flex-direction: column;
    overscroll-behavior: none;
  }
  .toolbarMobile .Component-horizontalScrollContainer-22 > div > div > div {
    overscroll-behavior: none;
  }
  .btnNoStyle {
    order: 1;
  }
  .width100Percent {
    width: fit-content !important;
  }
  .providerList.tables .MuiTable-root {
    width: 92% !important;
  }
  .providerList .MuiTableHead-root {
    display: none;
  }
  .providerList
    > div
    > div
    > table
    > tfoot
    > tr
    > td
    > div
    > div.MuiInputBase-root {
    width: 120px !important;
  }
  .providerList > div > div > table .MuiTablePagination-select {
    padding-left: 0 !important;
  }
  .providerList.tables .MuiTablePagination-select div {
    margin-right: 40px;
  }
  .providerList > div > div > table .MuiIconButton-root {
    padding: 12px 6px !important;
  }
  .addProviderForm {
    width: 100vw;
  }
}

@media screen and (max-width: 320px) {
  .providerList
    > div
    > div
    > table
    > tfoot
    > tr
    > td
    > div
    > div.MuiInputBase-root {
    width: 90px !important;
  }
  .jss52 span {
    padding: 6px;
  }
  .MuiTypography-root.MuiTypography-caption {
    width: 30px;
  }
  .providerList.tables .MuiTablePagination-select div {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-right: 0;
  }
}

/* Tablet and below */
@media screen and (max-width: 1011px) {
  .desktopOnlyFlexRow {
    flex-direction: column !important;
  }

  .desktopOnlyFlexRowJustifyStart {
    align-items: flex-start !important;
  }

  /* Row with search bar */
  .providerList .MuiFormControl-root.MuiTextField-root {
    flex-direction: column !important;
    align-items: flex-start;
  }
}

.MuiDrawer-paper {
  overflow-y: initial !important;
}

.MuiListItem-button {
  width: 239px !important;
}
/* @media only screen and (min-width: 1281px) {
  .App {
    font-size: 1em !important;
  }
} */

/* Add a Provider radio button */
.MuiRadio-colorSecondary.Mui-checked {
  color: #1081c8 !important;
}

/* MOBILE */

@media screen and (max-width: 767px) {
  main {
    margin-left: 10px;
    /* overscroll-behavior: none;
    overflow-y: hidden; */
  }
  /*https://annie-cannons.atlassian.net/browse/REF-258  */
  .App {
    width: 100% !important;
  }
  .login-page {
    width: 95vw;
  }
}

/* accordion Typography */
/* .MuiTypography-body1 { */
/* font-family: "Montserrat", sans-serif !important;
  font-weight: 600 !important; */
/* } */

.column {
  float: left;
}

.left,
.right {
  width: 7%;
}

.middle {
  width: 60%;
}

/* Clear floats after the columns */
.row:after {
  content: "";
  display: table;
  clear: both;
}

.MuiSlider-root {
  color: #0088d1;
  border-radius: 2px;
  height: 10.5px !important;
}

.slider-value {
  background: #4b4b4b;
  border-radius: 5px;
  color: #ffffff;
  font-size: 10px;
  text-align: center;
  float: left;
  padding: 5px;
}

.width80Percent {
  font-family: "Open Sans";
  color: #4b4b4c;
}

.header {
  padding-bottom: 3rem;
}

.label-help-text {
  color: #007db3;
  font-size: small;
}

.helpContact input {
  width: 100%;
  display: block;
  box-sizing: border-box;
  padding: 8px;
  font-size: 14px;
  height: 3em;
}

.helpContact textarea {
  padding: 8px;
  font-size: 14px;
}
